// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-20240909-tba-js": () => import("./../../../src/pages/20240909-tba.js" /* webpackChunkName: "component---src-pages-20240909-tba-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dosometest-js": () => import("./../../../src/pages/dosometest.js" /* webpackChunkName: "component---src-pages-dosometest-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-module-calc-js": () => import("./../../../src/pages/module-calc.js" /* webpackChunkName: "component---src-pages-module-calc-js" */),
  "component---src-pages-r-js": () => import("./../../../src/pages/r.js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-rl-js": () => import("./../../../src/pages/rl.js" /* webpackChunkName: "component---src-pages-rl-js" */),
  "component---src-pages-webview-test-js": () => import("./../../../src/pages/webview-test.js" /* webpackChunkName: "component---src-pages-webview-test-js" */),
  "component---src-template-redirect-js": () => import("./../../../src/template/redirect.js" /* webpackChunkName: "component---src-template-redirect-js" */)
}

